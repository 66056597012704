import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import Login from '@/services/Login'
import { ResponseError, useErrorOutput } from '@/app/error-output'
import { useRoute } from 'vue-router'
import Users, { User } from '@/services/Users'
import Permissions, { Permission } from '@/services/Permissions'

export type AppState = 'INIT' | 'READY' | 'ERROR' | 'UNAUTHORIZED'

const PBX_USER = import.meta.env.VITE_AUTH_ID
const PBX_PASS = import.meta.env.VITE_AUTH_PW

export const useAppStore = defineStore('app-store', () => {
	const authToken = ref<string>('')
	const visited = ref<string>('')
	const appState = ref<AppState>('INIT')
	const user: Ref<User | null> = ref(null)
	const userPermissions = ref(new Array<Permission>())

	const route = useRoute()

	const deleteToken = (): void => {
		authToken.value = ''
	}

	const storeToken = (t: string): void => {
		authToken.value = t.trim()
	}

	const deleteVisit = (): void => {
		visited.value = ''
	}

	const setVisit = (set: boolean): void => {
		if (set) {
			visited.value = '' + Date.now()
		} else {
			deleteVisit()
		}
	}

	const setToken = (token?: string): void => {
		if (!token || !token.trim().length) {
			deleteVisit()
			deleteToken()
		} else {
			storeToken(token)
			setVisit(true)
		}
	}

	const getVisit = () => visited.value

	const login = async () => {
		const token = '' + (route.query.token ?? '')
		const loginClient = new Login()

		if (token) {
			authToken.value = token
			loginClient.refresh()

		} else if (process.env.NODE_ENV === 'development' && PBX_USER && PBX_PASS) {
			try {
				await loginClient.login({ id: PBX_USER, password: PBX_PASS })
			} catch (e) {
				useErrorOutput().errorMessage(e as ResponseError | string)
				appState.value = 'UNAUTHORIZED'
			}
		} else {
			appState.value = 'UNAUTHORIZED'
		}
	}

	const logout = async () => {
		try {
			await new Login().logout()
		} catch (e) {
			console.error(e)
		}

		deleteToken()
	}

	const fetchMe = async () => {
		return await new Users().me.then((userData) => {
			if (userData) {
				user.value = userData
			}
		})
	}

	const myId = () => '' + user.value?.id

	const fetchPermissions = async () => {
		const userId = user.value?.id as number
		if (!isNaN(userId)) {
			await new Permissions().getByUser(userId).then(result => {
				userPermissions.value.push(...result)
			})
		}
	}

	const hasPermissions = computed(() => (permissions: Permission[]): boolean => {
		let cnt = 0
		permissions.forEach((p) => {
			if (userPermissions.value.findIndex(up => up.id === p.id) >= 0) {
				cnt++
			}
		})
		return cnt === permissions.length
	})

	return {
		appState,
		authToken,
		hasPermissions,
		setToken,
		setVisit,
		getVisit,
		login,
		logout,
		fetchMe,
		fetchPermissions,
		myId
	}
})
