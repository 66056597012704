import utf8 from 'utf8'
import base64 from 'base-64'
import { sha512 } from 'sha512-crypt-ts'
import { delay } from 'lodash'
import { useAppStore } from '@/app/app-store'
import { ResponseError } from '@/app/error-output'
import RestClient from './RestClient'

export enum LoginTypes {
	INTERNAL = 'Internal',
	AD = 'ActiveDirectory'
}

export type AuthData = {
	id: string
	password: string

}

type ErrorResponse = {
	code?: string
	message?: string
	responseJSON?: Record<string, string>
	statusText?: string
}

type TokenData = {
	token: string
}

export type LoginData = {
	loginType: LoginTypes
	nonce: string
	secret?: string
}

export default class Login {
	static #revisitInMillis = 4 * (3600000 - 60000) // 3h 59 min
	url = '/rest/login'
	rest

	messages: Record<string, string> = {
		'b68412a8-6a6e-4bb5-8afc-0503f620ff4e': 'de.easywe.catweb.servlets.LoginServlet.invalidLogin',
		'69af3342-00f0-4c16-af1c-c44711343e65': 'de.easywe.catweb.servlets.LoginServlet.authenticationError',
		'5b1e1f96-762f-440e-9dec-aea74d2e8047': 'de.easywe.catweb.servlets.LoginServlet.authenticationError',
		'5137e1c9-f3e7-4b5a-9fc7-578283546650': 'de.easywe.catweb.servlets.LoginServlet.authenticationError',
		'2f2de7fa-c92d-467e-bf8b-ff023773f85e': 'license.error.unauthorizedLicenseType'
	}

	private appStore

	constructor() {
		this.appStore = useAppStore()
		this.rest = new RestClient(this.url, false)
		return this
	}

	static createHash = (login: LoginData, auth: AuthData): string => {
		if (login.loginType === LoginTypes.AD) {
			const toEncode = utf8.encode(auth.id + login.nonce + auth.password)
			return base64.encode(toEncode)
		}
		const toEncode = auth.id + login.nonce + sha512.hex(auth.password)
		return auth.id + ':' + sha512.hex(toEncode)
	}

	errorHandling = (error: string | ErrorResponse, callback?: (args?: string[]) => void, msgs?: string[]) => {
		if (error !== undefined && error) {
			if (!msgs) {
				msgs = []
			}
			let msg = ''

			if (typeof error === 'string') {
				msg = error
			} else if (error.responseJSON) {
				const err = (error.responseJSON as ErrorResponse)
				if (err.message && err.code) {
					if (Object.hasOwn(this.messages, err.code)) {
						msg = this.messages[err.code]
					} else {
						msg = 'Code: ' + this.messages[err.code]
						msg += ', Message: ' + err.message
					}
				} else if (error.statusText) {
					msg = error.statusText
				}
			}
			if (typeof callback !== 'function') {
				console.log(msg)
			} else {
				msgs.push(msg)
				callback(msgs)
			}
		}
	}

	getLogin = (): Promise<Response> => {
		return this.rest.response
	}

	login = async (auth: AuthData, callback?: () => void): Promise<ResponseError | boolean> => {
		if (this.appStore.authToken) {
			return Promise.resolve(true)
		}

		return await this.getLogin()
			.then((response) => response.json(), (response) => this.errorHandling(response.json()))
			.then((data) => this.processLogin(data, auth))
			.then((auth) => this.postLogin(auth))
			.then((response) => response.json())
			.then((data: TokenData | ResponseError): Promise<ResponseError | boolean> => {
				const td = data as TokenData

				if (td.token && this.rest.setAuth(td.token)) {
					callback?.()
					this.refresh()

					return Promise.resolve(true)
				}

				return Promise.reject(data)
			})
	}

	logout = async () => {
		await this.rest.delete()
		return this
	}

	postLogin = async (login: LoginData): Promise<Response> => {
		return this.rest.post({
			body: login as unknown as BodyInit
		})
	}

	processLogin = (login: LoginData, auth: AuthData) => {
		const secret = Login.createHash(login, auth)
		if (secret) {
			login.secret = secret
		}
		return login
	}

	refresh = (): void => {
		const refreshLogin = async () => {
			try {
				await this.getLogin()
				this.appStore.setVisit(true)
				this.refresh()
			} catch (e) {
				// TODO: call refresh API from Client if implemented
				console.error(e)
			}
		}

		delay(refreshLogin, this.revisit())
	}

	revisit = (): number => {
		const visit = this.appStore.getVisit()

		if (visit && this.appStore.authToken && Date.now() >= parseInt(visit, 10) + Login.#revisitInMillis) {
			return 0
		}

		return Login.#revisitInMillis
	}
}
