import { merge } from 'lodash'
import { useAppStore } from '@/app/app-store'

const PBX_BASE = process.env.NODE_ENV === 'development' ? import.meta.env.VITE_PBX_BASE : `https://${window.location.host}`

export default class RestClient {
	#authenticate = true
	#options = {
		headers: new Headers({
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Version': '2'
		}),
		method: 'GET',
		mode: 'cors'
	}

	#url: string = PBX_BASE + '/rest'

	private appStore

	constructor(url?: string, needsAuth?: boolean, options?: RequestInit) {
		this.appStore = useAppStore()

		if (url) {
			this.#url = PBX_BASE + url
		}
		if (options) {
			this.setOptions(options as RequestInit)
		}
		if (needsAuth !== undefined) {
			this.#authenticate = needsAuth
		}
		if (this.#authenticate) {
			this.setAuth()
		}
	}

	get response(): Promise<Response> {
		(this.#options as RequestInit).body = null
		return this.request(({ method: 'GET' } as RequestInit))
	}

	async request(options?: RequestInit): Promise<Response> {
		if (options) {
			this.setOptions(options as RequestInit)
		}
		if (!this.#authenticate || this.setAuth()) {
			return await fetch(this.#url, this.#options as RequestInit)
		}
		return Promise.reject({
			code: 'b68412a8-6a6e-4bb5-8afc-0503f620ff4e',
			message: 'missing token, bro!'
		})
	}

	setAuth = (token?: string): boolean => {
		if (token) {
			this.appStore.setToken(token)
		} else {
			token = this.appStore.authToken
		}
		if (token) {
			this.#options.headers.set('authToken', token)
			return true
		}
		return false
	}

	setOptions = (options: RequestInit) => {
		(this.#options as RequestInit).body = null
		if (options.body) {
			options.body = JSON.stringify(options.body)
		}
		merge(this.#options, options)
	}

	delete(options?: RequestInit) {
		this.#options.method = 'DELETE'
		return this.request(options)
	}

	post(options?: RequestInit) {
		this.#options.method = 'POST'
		return this.request(options)
	}

	put(options?: RequestInit) {
		this.#options.method = 'PUT'
		return this.request(options)
	}

	set url(url: string) {
		this.#url = PBX_BASE + url
	}
}
